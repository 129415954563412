import React from "react";
import Head from "../../components/Head.js";
import Layout from "../../components/Layout.js";
import RightSidebar from "../../components/RightSidebar.js";

import RecommendTop from "../../components/RecommendTop.js";
import RecommendOther from "../../components/RecommendOther.js";

const allRecommendations = require("../recommendations.json");

// mark
const recommendPlaceInfo = allRecommendations[3];

const TestPage = () => {
  return (
    <>
      <Head
        title={`Austin City Tacos Recommends: ` + recommendPlaceInfo.title}
        description={`One of our top recommendations is ${recommendPlaceInfo.title}`}
      />
      <Layout>
        <main>
          <div className="main-wrapper main-wrapper-one-sidebar">
            <div className="primary-wrapper">
              <h1>Austin City Tacos Recommends: {recommendPlaceInfo.title}</h1>

              <RecommendTop data={recommendPlaceInfo}></RecommendTop>

              {/* battle */}
              <div className="big-description">
                <p>
                  Opened by Tyson and Cherry Blankemeyer Princess, this spot
                  might be our favorite line-up of breakfast tacos. This taco
                  joint felt so genuine and unique, and we have visited more
                  times than we can count. To give you a hint, the last two
                  choices we ate was "Princess Leia" and "King George". The
                  Princess Leia is an amazing blend of potato, egg, and cheese.
                  The King George is migas, fried avocado, bacon, and creamy Sri
                  Racha.
                </p>
                <p>
                  Tyson's Tacos does have a covered patio that is partially
                  closed in, so always available for your aching hearts. They
                  have a larger menu than most of the competitors, including a
                  "burnt ends" taco that we recommend to everyone. Located in
                  the Airport Corridor, it's in between Jewboy Burgers and The
                  Omelettry.
                </p>
              </div>
            </div>

            <div className="right-sidebar">
              <RecommendOther title={recommendPlaceInfo.title}></RecommendOther>
              <RightSidebar></RightSidebar>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default TestPage;
